.days-list {
  display: flex;
  .day {
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 34px;
    width: 34px;
    color: #8c4091;
    border: 1px solid #8c4091;
    border-radius: 50%;
    margin-right: 10px;
    p {
      display: flex;
      justify-content: center;
      margin-top: 2px;
      font-weight: bold;
      font-size: 12px;
    }
  }

  .opacity-0 {
    opacity: 0.4;
  }
  .opacity-1 {
    opacity: 1;
  }
}

@media screen and (max-width: 768px) {
  .days-list {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}
