.cityhalls {
  .cityhall {
    max-width: calc(50% - 15px);
  }
  @media screen and (max-width: 768px) {
    //Comentado debido a que se espera una opinion de luis a respecto de que le parece este cambio
    /*
    .section-title {
      h2 {
        font-size: 18px;
      }
      img {
        max-width: 20px;
      }
    }*/
    .scrollable {
      flex-wrap: wrap;
      flex-direction: column-reverse;

      form {
        margin: 0.5rem 0 0.5rem;
      }
    }
    .cityhall {
      max-width: initial;
    }
  }
}
