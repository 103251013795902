.imageSide {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  margin-right: 2rem;
  img {
    visibility: hidden;
    max-height: 480px;
    object-fit: scale-down;
  }
}
.textSide {
  padding: 2.2rem !important;
  h2 {
    font-size: 1.3rem;
    line-height: 1.5rem;
    font-weight: bold;
    color: #8b2c75;
    @media screen and (min-width: 768px) {
      font-size: 2.3rem;
      line-height: 3.5rem;
    }
  }
}
.container {
  margin-right: 2.2em;
  @media screen and (max-width: 768px) {
    margin-right: unset;
  }
}

.description {
  min-height: 170px;
  font-size: 18px;
  line-height: 30px;
  color: #333333;
  padding-right: 1rem;
  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
  @media screen and (max-width: 768px) {
    padding-right: unset;
    min-height: 150px;
  }
}
.descriptionBlock {
  scrollbar-color: #eb0189;
  scrollbar-width: thin;
  &::-webkit-scrollbar-thumb {
    background: #eb0189;
    height: 5px;
    border-radius: 4px;
  }
}
.separador {
  height: 1px;
  width: 100%;
  background: #cccccc;
  margin: 2rem auto 0;
  @media screen and (min-width: 768px) {
    margin: 3rem auto;
  }
}
