.onCloseButton {
  all: unset;
  position: absolute;
  top: 15px;
  left: 14px;
}

.container {
  position: relative;
  background-color: white;
  display: flex;
  flex-direction: column;
  max-height: min-content;
  max-width: 66%;
  height: max-content;
  position: fixed;
  top: 20%;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 99999;
  width: 350px;
  min-height: 260px;
  border-radius: 20px;
}
