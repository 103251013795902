.imageSide {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  margin-right: 2rem;
  img {
    visibility: hidden;
    max-height: 480px;
    object-fit: scale-down;
  }
}
.backButton {
  position: absolute;
  top: 2rem;
  right: 2rem;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  border: none;

  font-size: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    visibility: visible;
    max-width: 0.8rem;
    width: 100%;
    object-fit: scale-down;
    margin-right: 0.5rem;
  }
}
.textSide {
  padding: 2.2rem;
  max-width: 600px !important;

  .top {
    h2 {
      font-size: 1.3rem;
      line-height: 1.5rem;
      font-weight: bold;
      color: #8b2c75;
      @media screen and (min-width: 768px) {
        font-size: 2.3rem;
        line-height: 3.5rem;
      }
    }
    .category {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0.5rem auto;
      img {
        max-width: 45px;
        //margin-right: 0.5rem;
        filter: invert(42%) sepia(6%) saturate(10%) hue-rotate(33deg)
          brightness(89%) contrast(82%);
      }
      h6 {
        font-size: 0.9rem;
        padding: 0 0 0.5rem 0;
      }
      @media screen and (min-width: 768px) {
        margin: 1.3rem auto;
        h6 {
          font-size: 1.3rem;
          padding: 0;
        }
      }
    }
  }
  .bottomContent {
    flex-wrap: wrap;
    .left {
      margin: 1.5rem 0;
      display: flex;
      flex-direction: column;
    }
    .distance {
      font-size: 0.9rem;
    }
    @media screen and (min-width: 768px) {
      flex-wrap: nowrap;
      .left {
        margin-bottom: 0;
        align-items: flex-end;
      }
    }
  }
}
.description {
  font-size: 18px;
  line-height: 30px;
  color: #333333;
  & + .description {
    margin-bottom: 3rem;
  }
}
.cityHallRedirect {
  cursor: pointer;
  h5 {
    font-size: 1rem;
  }
}
.descriptionBlock {
  max-height: 250px;
  overflow-y: scroll;
  scrollbar-color: #eb0189;
  scrollbar-width: thin;
  &::-webkit-scrollbar-thumb {
    background: #eb0189;
    height: 5px;
    border-radius: 4px;
  }
}
.separador {
  height: 1px;
  width: 100%;
  background: #cccccc;
  margin: 2rem auto;
  @media screen and (min-width: 768px) {
    margin: 2rem auto;
  }
}

.map {
  margin-bottom: 5.5rem;
}

.benefitPorcentate {
  margin-bottom: 3%;
  font-weight: bold;
  font-size: 28px;
  line-height: 35px;
  color: #df1d84;
}
