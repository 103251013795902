.profileRegister {
  //label and placeholder shown
  label {
    position: relative;
    span {
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      padding: 0.8rem 0;
      transition: all ease-in 0.5s;
    }
    input {
      &::placeholder {
        visibility: hidden;
        color: white;
      }
      &:focus,
      &:not(:placeholder-shown),
      &:valid {
        &::placeholder {
          visibility: hidden;
          opacity: 0;
        }
        & ~ span {
          transform: translateY(-55%);
          font-size: 14px;
          transition: all ease-out 0.5s;
        }
      }
    }
  }

  .tags {
    text-align: left;
    label {
      color: rgba(51, 51, 51, 1);
    }
    input[type="checkbox"] {
      opacity: 0;
      height: 16px;
      width: 17px;
      z-index: 2;
      cursor: pointer;
      & + label.img {
        background-image: url("../../../assets/images/inactive-point.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        height: 16px;
        width: 17px;
        display: inline-block;
        padding: 0 0 0 0px;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        z-index: 1;
      }
      &:checked {
        & + label.img {
          background-image: url("../../../assets/images/active-point.svg");
        }
      }
    }
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
