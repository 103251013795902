.terms {
  .separador {
    height: 1px;
    width: 100%;
    background: #cccccc;
    margin: 3rem auto;
  }
}
.paragraph p {
  font-size: 1rem;
  margin: 0 0 1.5rem;
  text-align: justify;
}
