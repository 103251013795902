.contactForm {
  background-image: url("../../assets/images/banner.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  .banner {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    max-height: 360px;
  }
  img {
    visibility: hidden;
    object-fit: contain;
    width: 100%;
  }
  .text-side {
    h3 {
      max-width: 20ch;
    }
    h6 {
      font-size: 18px;
      line-height: 30px;
      font-weight: 300;
      color: #666666;
    }
  }
  @media screen and (max-width: 768px) {
    background-position: top;
    background-size: contain;
    background-color: white;
    .banner {
      position: relative;
      background-color: white;
    }
    .text-side {
      margin: 40px auto;
      padding-bottom: 40px;
    }
  }
}

.formContent {
  margin: 30px auto;
  border-radius: 5px;
  padding: 5rem 6rem !important;
  @media screen and (max-width: 768px) {
    padding: 2rem !important;
  }
  h3 {
    font-size: 36px;
    line-height: 46px;
  }
  h6 {
    font-size: 18px;
    line-height: 30px;
    color: #666666;
  }
  label {
    color: black;
    font-weight: bold;
  }
}
