.ticketModal {
  background-color: white;
  position: absolute;
  z-index: 19;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: max-content;
  width: max-content;
  border-radius: 10px;
  z-index: 99999;
  .qr-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .more-info {
      & > * {
        text-align: center;
      }
    }
  }
}
