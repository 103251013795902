.clickable {
  // transition: opacity 0.2s;
  cursor: pointer;
}

.clickable:active {
  // opacity: 0.6;
}

.card-clickable {
  // transition: opacity 0.2s;
  cursor: pointer;
  transition: transform 1s;
  &:hover {
    transform: translate(0px, -10px);
    box-shadow: 0.05rem 0.05rem 0.5rem rgba(0, 0, 0, 0.15) !important;
  }
}
