.container-accordion {
  margin: auto;
  .item {
    button.accordion {
      cursor: pointer;
      text-align: left;
      outline: none;
      width: 100%;
      background-color: white;
      border: none;
      border-radius: 0;
      p {
        display: flex;

        padding: 25px;
        color: #333;
        font-size: 15px;
        background-color: transparent;
        width: 100%;
      }
    }
    button.accordion p span {
      width: 1.25rem;
      height: 1.25rem;
      float: right;
      content: url("../../assets/images/Arrow.svg");
      margin-left: auto;
      margin-right: -10px;
    }

    .panel {
      max-height: 0;
      overflow: hidden;
      transition: 0.2s ease-out;
      p {
        font-size: 1rem;
        line-height: 1.9rem;
        text-align: justify;
        margin-bottom: 1.5%;
      }
    }
    hr {
      margin-top: 0;
      margin-bottom: 3%;
    }

    &.active {
      button.accordion p span {
        transition: 0.2s;
        transform: rotate(180deg);
      }
      .panel {
        padding: 0% 50px;
        padding-bottom: 3%;
        background-color: white;
        max-height: fit-content;
        display: block;

        //transition: 0.2s ease-out;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .container-accordion .item .panel p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
  }
}
