$spacing: 20px;
$borderRadius: 5px;

$cardHeight: 120px;
$cardWidth: 300px;

// COLORS
$lightBlue: #12b6ca;
$lighterBlue: rgba(89, 193, 223, 0.17);
$darkGray: #333333;
$gray: #626262;
$lightGray: #9fa3a7;
$lighterGray: #d8d8d8;

$primary: #12b6ca;
$violetAccent: #8b2c75;
$lightViolet: rgba(139, 44, 117, 0.15);
$magentaActions: #ed339a;
$magenta: #eb0189;
$ligthMagenta: #d83f98;
$cyan: #c7e8ec;
$cyanText: #00b2c8;
$magentaDisabled: rgba(230, 1, 126, 0.47);
$backgroundLightGay: #b8b7b7;

$backgroundGray: #f6f6f6;
$black: #000;
$white: #fff;

//FONTS
@font-face {
  font-family: "Encode";
  font-style: normal;
  font-weight: 400;
  src: local("EncodeSans"), local("EncodeSans"),
    url("./../assets/fonts/EncodeSans-Regular.ttf") format("truetype"); /* IE9 Compat Modes */
}
@font-face {
  font-family: "Encode";
  font-style: normal;
  font-weight: 600;
  src: local("EncodeSans"), local("EncodeSans"),
    url("./../assets/fonts/EncodeSans-SemiBold.ttf") format("truetype"); /* IE9 Compat Modes */
}
@font-face {
  font-family: "Encode";
  font-style: normal;
  font-weight: bold;
  src: local("EncodeSans"), local("EncodeSans"),
    url("./../assets/fonts/EncodeSans-Bold.ttf") format("truetype"); /* IE9 Compat Modes */
}

@font-face {
  font-family: "Encode";
  font-style: normal;
  font-weight: 900;
  src: local("EncodeSans"), local("EncodeSans"),
    url("./../assets/fonts/EncodeSans-Black.ttf") format("truetype"); /* IE9 Compat Modes */
}

@font-face {
  font-family: "Encode";
  font-style: normal;
  font-weight: 300;
  src: local("EncodeSans"), local("EncodeSans"),
    url("./../assets/fonts/EncodeSans-Thin.ttf") format("truetype"); /* IE9 Compat Modes */
}
//Layout
//html{
//  overflow-x: hidden;
//}

body.noScroll {
  overflow: hidden;
}

.scrollDescription {
  overflow-y: scroll;
  scrollbar-color: #eb0189;
  scrollbar-width: thin;
  max-height: 170px;
  @media screen and (max-width: 768px) {
    max-height: 170px;
  }
  &::-webkit-scrollbar-thumb {
    background: #eb0189;
    height: 5px;
    border-radius: 4px;
  }
}

// Typography
h2 {
  font-size: 3rem;
  line-height: 3.8rem;

  &.text-secondary {
    color: $violetAccent !important;
  }
  &.bold {
    font-weight: bold;
  }
  @media only screen and (max-width: 576px) {
    font-size: 1.5rem;
    line-height: 2.2rem;
  }
}
h5 {
  font-size: 1.5rem;
  line-height: 1.9rem;
  &.bold {
    font-weight: bold;
  }
  &.text-secondary {
    @media only screen and (max-width: 576px) {
      font-size: 1rem;
    }
  }
}
p {
  font-family: Encode, sans-serif;
  font-weight: normal;
  font-size: 0.7rem;
  line-height: 1.2rem;
  &.small {
    font-size: 0.8rem;
    line-height: 1.1rem;
    &.bold {
      font-weight: bold;
    }
  }
}

.text-magenta {
  color: $magenta;
}

ul {
  margin-bottom: 0;
  padding: 0;
  li {
    margin: 0.8rem 0;
  }
}
//Class to style unavailable elements
.used {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}
.logo-sub-title {
  @media only screen and (max-width: 576px) {
    width: 30px;
    margin-right: 10px !important;
  }
}

//Layout
@media screen and (min-width: 1200) {
  .container {
    max-width: 1440px;
    margin: auto;
    width: 100%;
  }
}
