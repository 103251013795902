.details {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
//wsp icon
.icon {
  width: 35px;
  height: 35px;
}

.iconContent {
  margin-top: 3%;
  width: min-content;
  height: max-content;
}

.marginButton {
  margin-bottom: 100px;
}

@media screen and (max-width: 768px) {
  .details {
    display: block;
  }
  .validity_to {
    margin-top: 7%;
  }
  .cardHTU {
    width: unset;
    height: unset;
  }
}

.combinateImage {
  @media screen and (max-width: 320px) {
    max-width: 230px !important;
    max-height: 60px !important;
  }
  max-width: 130px !important;
  max-height: 40px !important;
  width: 130px !important;
  height: 40px !important;
  min-width: 130px !important;
  min-height: 40px !important;
}
