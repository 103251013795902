.img {
  width: 50px;
  height: 50px;
  margin: -10px;
  filter: brightness(0) saturate(100%) invert(20%) sepia(25%) saturate(5025%)
    hue-rotate(287deg) brightness(97%) contrast(87%);
  @media screen and (max-width: 577px) {
    width: 25px;
    height: 25px;
  }
}
