.notifications {
  margin-bottom: 122px;
}
.notification {
  border-bottom: 2px solid #dddddd;
  padding: 27px 0;

  a {
    color: black;

    &:hover {
      text-decoration: none;
      color: black;
    }
  }

  .icon {
    margin-right: 50px;
    img {
      max-width: 44px;
      filter: brightness(0) saturate(100%) invert(83%) sepia(0%) saturate(0%)
        hue-rotate(94deg) brightness(97%) contrast(98%);
    }
  }
  .text-side {
    h6 {
      font-size: 20px;
      line-height: 25px;
      text-transform: capitalize;
    }
    span {
      font-size: 16px;
      margin: 0.5rem 0;
    }
  }
}
