.mainlogin {
  margin: 1.5em 0;
  .buttonGroup {
    padding: 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    button {
      color: #333333;
      font-size: 0.7rem;
      font-weight: 500;
      min-width: 200px;
      &:hover {
        background: none;
      }
      img {
        margin-right: 0.5rem;
      }
      &.blue {
        border: 1px solid #3b5998;
      }
      &.red {
        border: 1px solid #bb001b;
      }
      & + button {
        margin-top: 1rem;
      }
    }
  }

  input {
    padding: 0.5rem;
  }

  @media screen and (min-width: 768px) {
    margin: 5em 0;
    .buttonGroup {
      padding: 3rem 0;
      flex-direction: row;
      button {
        margin: 1rem 0;
        & + button {
          margin-left: 0.7rem;
        }
      }
    }
  }

  .registerCta {
    text-align: center;
    a {
      color: #eb0189;
      font-weight: 700;
    }
  }
}
.profileRegister {
  margin: 3rem 0;
  width: 100%;
  justify-content: space-between;
  h5 {
    margin: 25px 0;
  }
  input,
  select {
    max-width: auto;
    width: 100%;
    @media screen and (min-width: 768px) {
      max-width: 350px;
    }
  }
  .phone,
  .prefix {
    max-width: auto;
    border-radius: 0;
    border: none;
  }
}
.numberData {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 350px;
  p {
    font-size: 1rem;
    line-height: 1.3rem;
  }
  input {
    text-align: center;
    color: #999999;
    padding: 10px 0px;
  }
}
.form {
  justify-content: center;
  margin: 1rem auto;
  div {
    width: 100%;
    &.password {
      .info-text {
        color: #666666;
      }
    }
    .passRecovery {
      text-align: center;
      color: #666666;
      font-size: 0.7rem;
      font-weight: 500;
    }
    &:first-of-type {
      margin-bottom: 1.5rem;
    }
    label {
      width: 100%;
      position: relative;
      img {
        cursor: pointer;
        position: absolute;
        top: 0;
        bottom: 0.8rem;
        right: 0.8rem;
        margin: auto;
        max-width: 17px;
      }
    }
    .info-text {
      margin: 0.5rem auto;
    }
  }
  button {
    margin: 2.5rem auto 4rem;
  }
}
.formTitle {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 25px;
}

.registerEmail {
  max-width: 410px;
  width: 100%;
  h5 {
    margin: 5rem auto 3rem;
  }
  .loginCta {
    margin-bottom: 4rem;
    border-top: 2px solid #f4f4f4;
    padding: 3rem 0;
  }
}
