.textLeft {
  @media screen and (max-width: 550px) {
    position: absolute;
    transform: translateY(45px);
  }
}
.textRight {
  @media screen and (max-width: 558px) {
    position: absolute;
    transform: translateY(45px);
    right: 65px;
  }
}

.itemRange {
  height: 35px;
  width: 35px;
  border: solid 1px;
  @media screen and (max-width: 558px) {
    height: 23px;
    width: 23px;
  }
}

.itemRangeSelect {
  transition: 0.2s;
  height: 50px;
  width: 50px;
  border: solid 1px;
  @media screen and (max-width: 558px) {
    height: 28px;
    width: 28px;
  }
}
.range {
  height: 70px;
  min-width: 280px;
  width: 70%;
  @media screen and (max-width: 1200px) {
    width: 100%;
  }
  @media screen and (max-width: 400px) {
    min-width: 260px !important;
    width: 50% !important;
  }
}
