.faq {
  width: 100%;

  &__title {
    margin: 0;
    padding-bottom: 25px;
  }

  &__question {
    width: 100%;
    border-bottom: 1px solid rgba(139, 44, 117, 0.15);
  }

  &__question-button {
    width: 100%;
    display: flex;
    align-items: center;
    border: none;
    background-color: transparent;
    padding: 0px;
    cursor: pointer;
    padding-top: 18px;
    padding-bottom: 18px;

    &::after {
      margin-right: 17px;
      transform: rotate(90deg);
    }
  }

  &__desc {
    margin-top: 0;
    margin-bottom: 0;
    height: 0;
    overflow: hidden;
    transition: all 200ms ease-out;

    &.show-description {
      height: fit-content;
      padding: 2rem 0;
    }
  }
}
