button {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  padding: 0.5rem 1.3rem;
  border-radius: 1.8rem;
  border: none;
  border: 1px solid $white;
  color: $white;
  cursor: pointer;
  font-weight: normal;
  font-size: 1rem;
  line-height: 20px;
  &.primary {
    background-color: transparent;
    &--magenta {
      border: 1px solid $magenta;
      color: $magenta;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: max-content;
      img {
        max-width: 1rem;
        margin-left: 0.3rem;
        filter: brightness(0) saturate(100%) invert(16%) sepia(96%)
          saturate(5081%) hue-rotate(315deg) brightness(90%) contrast(107%);
      }
      &:hover {
        background: $magenta;
        color: white;
        img {
          filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
            saturate(7500%) hue-rotate(7deg) brightness(103%) contrast(101%);
        }
      }
    }
    &--blue {
      background-color: $lightBlue;
    }
    &--black {
      border: 1px solid black;
      color: black;
      display: flex;
      flex-wrap: nowrap;
      &:hover {
        background-color: black;
        color: white;
        transition: all 400ms ease;
        img {
          filter: invert(1);
        }
      }
    }
  }
  &.secondary {
    background-color: $white;
    color: $primary;
    &--border {
      border: 1px solid $lightBlue;
    }
    &--purple-border {
      color: $violetAccent;
      border: 1px solid $lightViolet;
    }
    &.secondary--blue {
      background-color: $lighterBlue;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: max-content;
      img {
        margin-left: 0.5rem;
      }
    }
    &.secondary--magenta {
      background-color: $magenta;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      // padding: 0.5em;

      img {
        margin-left: 0.5rem;
      }
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
    &.secondary--ligthMagenta {
      background-color: white;
      color: $violetAccent;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $violetAccent;
      // padding: 0.5em;

      img {
        margin-left: 0.5rem;
      }
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
    &.secondary--gray {
      background-color: $gray;
      font-size: small;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        max-width: 0.8rem;
        width: 100%;
        object-fit: scale-down;
        margin-right: 0.5rem;
      }
      &.disabled {
        // font-size: small !important;
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
    &.secondary--cyan {
      background-color: $cyan;
      color: $cyanText;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        margin-left: 0.5rem;
        filter: invert(47%) sepia(42%) saturate(961%) hue-rotate(139deg)
          brightness(107%) contrast(101%);
      }
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
    &.secondary--cyanWhite {
      background-color: $cyanText;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        margin-left: 0.5rem;
        filter: invert(47%) sepia(42%) saturate(961%) hue-rotate(139deg)
          brightness(107%) contrast(101%);
      }
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
    // &.secondary-disable{}
    &.secondary--purple {
      color: $violetAccent;
      background-color: $lightViolet;
      font-size: 0.9rem;
      margin-right: 0.3rem;
      padding: 0.3rem 1.15rem;
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 450px) {
        margin-right: 0.9rem;
        padding: 0.3rem 1.31rem;
      }
      img {
        margin-right: 0.5rem;
        max-width: 2.5rem;
        filter: brightness(0) saturate(100%) invert(20%) sepia(25%)
          saturate(5025%) hue-rotate(287deg) brightness(97%) contrast(87%);
      }

      &.selected {
        background-color: $magenta;
        color: $white;
        img {
          filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
            saturate(7500%) hue-rotate(7deg) brightness(103%) contrast(101%);
        }
      }
      &.purpleHover {
        padding: 10px 20px;
        &.selected {
          background-color: #8b2c75;
        }
      }
    }
  }
}

a {
  text-decoration: none;
  color: $white;
  &:hover {
    text-decoration: none;
  }
  &.active {
    color: red;
    position: relative;
    &::after {
      content: "";
      width: auto;
      height: 1px;
      background-color: white;
      position: absolute;
      bottom: -35%;
      left: 20%;
      right: 0;
      margin: auto;
    }
  }
}

input {
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: Encode, sans-serif;
  color: #333333;
  width: 100%;
  margin-bottom: 0.8rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  border-bottom: 1px solid #999999;
  padding: 0.8rem 0.5rem;
  &:focus {
    outline: transparent;
    border: 1px solid rgba(#00000000, 1);
  }
  &::placeholder {
    color: #999999;
  }
  &[type="date"] {
    width: 120px;
    height: 35px;
    text-transform: uppercase;
    -webkit-appearance: none;
    -moz-appearance: none;
    color: transparent;
    &.date-picker {
      border: 0.81px solid #272636;
      border-radius: 1.2rem;
      padding: 0.4rem 1.2rem;
      background: transparent;
      z-index: 2;
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: white;
      }

      &::-moz-placeholder {
        /* Firefox 19+ */

        color: white;
      }

      &:-ms-input-placeholder {
        /* IE 10+ */

        color: white;
      }

      &:-moz-placeholder {
        /* Firefox 18- */

        color: white;
      }
      &::-webkit-calendar-picker-indicator {
        background: transparent;
        bottom: 0;
        color: transparent;
        cursor: pointer;
        height: auto;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: auto;
      }
      &::-webkit-datetime-edit {
        color: transparent;
        display: none;
      }
    }
    &[name="birth_day"] {
      @extend select;
      background-image: none !important;
    }
  }
  &[type="number"] {
    border: 1px solid #999999;
    max-width: 60px;
    border-radius: 18.6px;
  }
  &[type="checkbox"] {
    -webkit-appearance: checkbox;
    -moz-appearance: checkbox;
  }
  &[type="password"],
  &.password {
    padding-right: 2.5rem;
  }
  &[type="phone"] {
    &.prefix {
      max-width: 50px;
    }

    &.phone {
      margin-left: 1.9rem;

      @media screen and (min-width: 768px) {
        max-width: calc(350px - 85px);
      }
    }
  }
}

textarea {
  background-color: transparent;
  color: #999999;
  border-radius: 18.6px;
  width: 100%;
  padding: 1rem;
  &:focus-visible {
    outline: none;
  }
}

label {
  color: #999999;
  &.checkbox {
    display: flex;
    color: black;
    img {
      max-width: 24px;
      &.active {
        opacity: 1;
      }
      &.inactive {
        opacity: 0.3;
      }
    }
    input {
      visibility: hidden;
      max-width: min-content;
    }
  }
  &.search.input {
    position: relative;
    width: 100%;
    margin-bottom: 0;
    input {
      border: 1px solid #cccccc;
      margin-bottom: 0;
      box-sizing: border-box;
      border-radius: 30px;
      padding: 0.7rem 1rem;
      max-height: 40px;
      min-width: 350px;
      width: 100%;
      font-size: 14px;
      background: white;
      @media only screen and (max-width: 415px) {
        width: 100%;
        min-width: auto;
      }
    }
    button {
      position: absolute;
      padding: 0;
      max-width: min-content;
      top: 0;
      bottom: 0;
      margin: 0;
    }
    svg {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 0;
      transform: translateX(-10px);

      &.clear {
        position: absolute;
        right: 10px;
        padding: 10px 0;
        color: #666666;
        display: none;
        cursor: pointer;
        max-width: 15px;
        &.visible {
          display: block;
        }
      }
    }
    &.focus {
      input {
        padding: 0.7rem 2rem;
      }
      svg {
        right: auto;
        left: 0;
        transform: translateX(10px);
        &.clear {
          right: 0;
          left: auto;
          margin: auto;
          transform: translateX(-10px);
        }
      }
    }
  }
  &.date-picker.placeholder {
    position: absolute;
    margin: auto;
    padding: 0.4rem 1.2rem;
    color: black;
    left: 0;
    right: 0;
    font-size: 1rem;
    img {
      max-width: 1.2rem;
      width: 100%;
      object-fit: contain;
      margin-right: 0.5rem;
    }
  }
}

select,
.border-rounded {
  @extend input;
  background-color: transparent;
  border: 1px solid #999999;
  border-radius: 25px;
  padding: 10px 20px;
  color: #999999;
  margin: 10px 0 25px;
  position: relative;
  background-image: url("../assets/images/down-arrow.svg");
  background-repeat: no-repeat;
  background-position: center right 20px;

  &:focus {
    outline: transparent;
  }
  &::placeholder {
    color: #999999;
  }
  &.no-arrow {
    background-image: none;
  }
}

.ratio-1x1 {
  &.container {
    position: relative;
    padding-top: 100%;
  }

  &.item {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
}

.ratio-3x2 {
  &.container {
    position: relative;
    padding-top: 66.66%;
  }

  &.item {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
  }
}

$finalHeight: 250px;
$finalWidth: 3 * $finalHeight;
$scrollBarHeight: 5px;

/* width */
::-webkit-scrollbar {
  width: 7px;
  height: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c2c2c2;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8d8d8d;
}

.scrollable {
  overflow-x: scroll;
  cursor: grabbing;
  -ms-overflow-style: auto; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  scrollbar-color: transparent;
  width: 100%;
  max-height: min-content;
  margin: 0;
  padding-top: $scrollBarHeight;
  overflow-y: auto;
}

svg {
  &.subtitle-home {
    max-width: 25px;
    height: auto;
    @media only screen and (max-width: 576px) {
      max-width: 16px;
      height: auto;
    }
  }
}

.slider-button {
  position: relative;
  top: 7px;
  cursor: pointer;

  &.inactive {
    opacity: 0.2;
  }
  @media screen and (max-width: 992px) {
    display: none;
  }
}
.checkboxes {
  text-align: left;
  label {
    color: rgba(51, 51, 51, 1);
  }
  input[type="checkbox"] {
    opacity: 0;
    height: 16px;
    width: 17px;
    z-index: 2;
    cursor: pointer;
    & + label.img {
      background-image: url("../assets/images/inactive-point.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      height: 16px;
      width: 17px;
      display: inline-block;
      padding: 0 0 0 0px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      z-index: 1;
    }
    &:checked {
      & + label.img {
        background-image: url("../assets/images/active-point.svg");
      }
    }
  }
}

button.secondary.secondary--magenta:hover {
  background-color: transparent;
  color: $magenta;
  border-color: $magenta;
  img {
    filter: brightness(0) saturate(100%) invert(16%) sepia(96%) saturate(5081%)
      hue-rotate(315deg) brightness(100%) contrast(107%) !important;
  }
}
button.secondary.secondary--ligthMagenta:hover {
  background-color: transparent;
  color: $magenta;
  border-color: $magenta;
  img {
    filter: brightness(0) saturate(100%) invert(16%) sepia(96%) saturate(5081%)
      hue-rotate(315deg) brightness(100%) contrast(107%) !important;
  }
}
button.secondary.secondary--cyan:hover {
  background-color: transparent;
  color: $cyanText;
  border-color: $cyan;
  // img {
  //   filter: brightness(0) saturate(100%) invert(16%) sepia(96%) saturate(5081%)
  //     hue-rotate(315deg) brightness(100%) contrast(107%) !important;
  // }
}
button.secondary.secondary--cyanWhite:hover {
  background-color: transparent;
  color: $cyanText;
  border-color: $cyan;
  // img {
  //   filter: brightness(0) saturate(100%) invert(16%) sepia(96%) saturate(5081%)
  //     hue-rotate(315deg) brightness(100%) contrast(107%) !important;
  // }
}
button.secondary.secondary--gray:hover {
  background-color: transparent;
  color: $gray;
  border-color: $gray;
  // img {
  //   filter: brightness(0) saturate(100%) invert(16%) sepia(96%) saturate(5081%)
  //     hue-rotate(315deg) brightness(100%) contrast(107%) !important;
  // }
}
