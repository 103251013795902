.top {
  display: flex;
  flex-direction: column;
  align-items: center;
  h2 {
    font-size: 1.3rem;
    line-height: 1.5rem;
    font-weight: bold;
    color: #8b2c75;
    @media screen and (min-width: 768px) {
      font-size: 2.3rem;
      line-height: 3.5rem;
    }
  }
  .category {
    width: 75vw;
    display: flex;
    justify-content: space-around;
    margin: 1rem auto;
    margin-bottom: 3rem;

    @media screen and (max-width: 450px) {
      flex-direction: column;
      align-items: center;
      margin-bottom: 1rem;
    }

    img {
      max-width: 16rem;
      height: auto;
    }
    h6 {
      padding: 1rem;
      font-size: 1.3rem;
      text-align: center;
      a {
        color: #eb0189;
      }
    }
  }
}
