.description {
  font-size: 18px;
  line-height: 30px;
  color: #333333;
  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
  @media screen and (min-width: 768px) {
    margin-bottom: 1rem;
  }
}

.subtitle {
  font-size: 24px;
}

.separador.deco {
  img {
    min-height: 4px;
    object-fit: cover;
  }
}
