.desktop {
  display: none;
}
.mobile {
  display: block;
  .snackBar {
    z-index: 4;
    position: relative;
  }
}
.menuMobile {
  overflow: scroll;
  background: white;
  max-height: 90vh;
  width: 100%;
  z-index: 2;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  //transform: translateY(-100%);
  opacity: 1;
  transition: all ease-in 1s;
  position: absolute;
  a {
    color: black;
    display: block;
    margin-bottom: 2rem;
  }
}
.active {
  transform: translateY(0);
  opacity: 1;
  transition: all ease-in 1s;
}

.separador {
  min-height: 1px;
  width: 100%;
  background: #cccccc;
  margin: 0 auto 2rem;
}

.userMenu {
  background: #ffffff;
  margin-bottom: 2rem;
  a,
  span {
    color: #666666;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    cursor: pointer;
  }
}

.userCta {
  display: flex;
  flex-wrap: wrap;
  margin-left: 0;
  @media screen and (min-width: 768px) {
    a + a {
      margin-left: 1rem;
    }
  }
}

.snackBar {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)),
    #12b6ca;
  .navigation {
    display: flex;
    align-items: center;
    a {
      font-size: 0.9rem;
    }
    ul {
      a {
        color: rgba(255, 255, 255, 0.7);
        margin-left: 1rem;
        font-size: 0.85rem;
      }
    }
    .appDownload {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 1.9rem 0 1.5rem;
      p {
        color: white;
        margin: 0 0.7rem;
        font-size: 0.9rem;
      }
      a + a {
        margin-left: 0.5rem;
      }
      img {
        max-width: 1.3rem;
        width: 100%;
      }
    }
  }
  .languages {
    display: flex;
    justify-content: center;
    align-items: center;
    div {
      color: rgba(255, 255, 255, 0.7);
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.7rem;
      margin-right: 1rem;
      cursor: pointer;
      &.active {
        color: white;
      }
    }
  }
}

header {
  padding: 1.2rem 0;
  .logo {
    img {
      max-width: 8.75rem;
    }
  }
  .navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    ul {
      padding: 0;
      display: flex;
      a {
        margin-right: 1.9rem;
        color: white;
      }
    }
    .userCta {
      display: flex;

      a + a {
        margin-left: 1rem;
      }
    }
  }
}

@media screen and (min-width: 992px) {
  .desktop {
    display: block;
  }
  .mobile {
    display: none;
  }
}

.hasNotifications {
  width: 9px;
  height: 9px;
  position: absolute;
  transform: translateX(8px) translateY(6px);
  border-radius: 50%;
  background-color: #eb0189;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 7px !important;
  color: white !important;
}

.miAgenda {
  width: 16px;
  height: 16px;
  filter: brightness(0) saturate(100%) invert(16%) sepia(96%) saturate(5081%)
    hue-rotate(315deg) brightness(90%) contrast(107%);
}
