.userProfile {
  // margin: 5rem auto;
  h6:not(.points) {
    font-size: 18px;
    line-height: 22px;
  }
  .points {
    color: #666666;
  }
  p.description {
    font-size: 16px;
    line-height: 20px;
    color: #666666;
  }
}
