.modalContainer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1021;
}
.test-modal {
  z-index: 9999999999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: max-content;
  width: max-content;
  border-radius: 10px;
  box-shadow: 20px 20px 60px #8b8b8b, -20px -20px 200px #88888862;
  .content {
    padding: 0 2.5rem;
    .clipboard {
      label {
        border: 1px solid #bbbbbb;
        color: #666666;
        border-radius: 25px;
        padding: 10px 0 10px 20px;
        button {
          color: #8c4091;
          padding: 0 10px;
          margin-left: 1rem;
          font-weight: bold;
        }
      }
    }
  }
  .share {
    margin-top: 0.3rem;
    margin-bottom: 1.7rem;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
    list-style-type: none;
    li {
      a {
        color: red;
        img {
          opacity: 0.7;
          border: 1.5px solid #66666673;
          border-radius: 50%;
          padding: 10px;
        }
        &:hover,
        &:focus {
          outline: transparent;
          img {
            opacity: 1;
            transition: all linear 100ms;
            border: 1.5px solid #666666;
          }
        }
      }
    }
  }
}
