.reset-password {
  .title {
    margin-bottom: 0.8rem;
  }
  h6 {
    font-size: 14px;
    line-height: 17px;
    text-align: center;
  }
  form {
    margin: 3.8rem auto;
    max-width: 350px;
    .password {
      margin-bottom: 1.5rem;
    }
    button {
      margin: 1.5rem auto;
    }
    label {
      width: 100%;
      position: relative;
      margin-bottom: 0;
      img {
        cursor: pointer;
        position: absolute;
        top: 0;
        bottom: 0.8rem;
        right: 0.8rem;
        margin: auto;
        max-width: 17px;
      }
    }
    .info-text {
      font-size: 0.85rem;
      line-height: 1.1rem;
      color: #666666;
      font-weight: normal;
    }
  }

  .separador {
    min-height: 1px;
    max-width: 410px;
    width: 100%;
    background: #cccccc;
    margin: 0 auto 2rem;
  }
  @media screen and (min-width: 768px) {
    margin: 2rem auto;
  }
}
