.paradores-container {
  position: fixed;
  z-index: 99999999;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: white;
  max-height: 100vh;
  overflow: auto;
}

//paradores page
/*---------------------------------------------------------------------------------------------*/
body {
  padding: 0;
  margin: 0;
  overflow: auto;
  overflow-x: hidden;
}

a {
  text-decoration: none !important;
}

/* img center */
.center-block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

/* margins */
.rt0 {
  margin-top: 0 !important;
}

.rt10 {
  margin-top: 10px;
}

.rt20 {
  margin-top: 20px;
}

.rt30 {
  margin-top: 30px;
}

.r0 {
  margin-bottom: 14px;
}

.r10 {
  margin-bottom: 10px;
}

.r20 {
  margin-bottom: 10px;
}

.r30 {
  margin-bottom: 10px;
}

.border-no {
  border: 0;
}

/* navbar */
.back-navbar {
  background-color: #0cb2c8;
  min-height: 50px;
  border-radius: 0;
  border-bottom: 2px solid #fff;
}

/* Logo ReCreo top */
.img-navbar {
  height: 120px;
  padding: 10px;
  display: inline;
}

@media (max-width: 1024px) {
  .img-navbar {
    height: 80px;
  }
}

/* Main images */
.img {
  background-image: url("../../assets/images/paradores/img/500X500.png");
}

@media (min-width: 800px) {
  .img {
    background-image: url("../../assets/images/paradores/img/500X500.png");
  }
}

@media (min-width: 1000px) {
  .img {
    background-image: url("../../assets/images/paradores/img/1920X.png");
  }
}

/* Wrapper paradores */
.wrapper-red {
  background-color: #e02892;
  padding-bottom: 40px;
}

/* Wrapper app */
.wrapper-green {
  background-color: #0cb2c8;
  border-top: 2px solid #fff;
  padding-top: 60px;
  padding-bottom: 100px;
  text-align: center;
}

@media (max-width: 1024px) {
  .wrapper-green {
    padding-top: 30px;
    padding-bottom: 60px;
  }
}

/* Wrapper line-up */
.wrapper-lightgreen {
  border-top: 2px solid #fff;
  padding-top: 80px;
  padding-bottom: 100px;
  text-align: center;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../assets/images/paradores/img/bandas-background.png");
}

/* app texto */
.app-mensaje {
  font-size: 36px;
  color: #fff;
  margin-top: 30px;
  margin-bottom: 50px;
}

/* app badges img & texto */
@media (max-width: 1024px) {
  .img-badges {
    height: 44px;
  }

  .app-mensaje {
    font-size: 22px;
  }
}

/* line-up bandas texto */
.bandas {
  font-size: 34px;
  line-height: 44px;
  font-weight: 900;
}

.bandas-white {
  color: #fff;
}

.bandas-red {
  color: #e02892;
}

.bandas-app {
  font-size: 30px;
  margin-top: 50px;
  color: #e02892;
}

.bandas-title {
  display: table;
  margin: 0px auto 40px auto;
  padding: 15px;
  color: #ffffff;
  background-color: #e02892;
  font-size: 40px;
  font-weight: bold;
}

@media (max-width: 1024px) {
  .bandas-title {
    font-size: 30px;
  }

  .bandas {
    font-size: 24px;
  }

  .bandas-app {
    font-size: 24px;
    margin-top: 40px;
    color: #e02892;
  }
}

/* paradores texto */
.paradores {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.paradores h3 {
  font-size: 30px;
  font-weight: 900;
  color: #fff;
  text-shadow: 1px 1px 2px black;
}

.paradores:hover h3 {
  color: #0cb2c8;
}

.paradores p {
  font-size: 18px;
  color: #fff;
}

.paradores i {
  color: #fff;
  padding: 10px;
  text-shadow: 1px 1px 2px black;
}

.paradores:hover i {
  color: #0cb2c8;
}

.paradores-title-2 {
  float: right;
}

@media (max-width: 1024px) {
  .paradores h3 {
    font-size: 22px;
    margin-bottom: 4px;
  }

  .paradores p {
    font-size: 16px;
    color: #fff;
  }

  .paradores {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

/* footer */
.footer-recreo {
  background-color: #e02892;
  border-top: 2px solid #fff;
  padding: 30px;
}

@media (max-width: 1024px) {
  .footer-recreo img {
    margin-bottom: 20px;
  }
}
