.modal {
  position: relative;
  background-color: white;
  display: flex;
  flex-direction: column;
  width: max-content;
  min-height: max-content;
  width: 300px;
  min-height: 300px;
  border-radius: 20px;
}

.onCloseButton {
  all: unset;
  position: absolute;
  top: 15px;
  left: 15px;
}

.container {
  background-color: white;
  display: flex;
  flex-direction: column;
  max-height: min-content;
  height: max-content;
  position: fixed;
  top: 20%;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 999999999;
  width: 300px;
  min-height: 300px;
  border-radius: 20px;
}
