.howtouseModal {
  background-color: white;
  position: absolute;
  z-index: 99999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: max-content;
  width: max-content;
  border-radius: 18px;
  max-width: 500px;
}
