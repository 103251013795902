.ticket {
  .title {
    font-size: 36px;
  }
  .time {
    font-weight: 300;
    line-height: 47px;
  }
  .locality {
    font-size: 18px;
  }
  .address {
    font-size: 16px;
  }
}
.content-block {
  .qr-block {
    margin: 20px auto;
    padding: 0;
    @media screen and (min-width: 768px) {
      margin: 0;
    }
  }
  .more-info {
    .code {
      font-size: 18px;
      line-height: 30px;
    }
    .sub {
      font-size: 18px;
      color: #666666;
    }
  }
}
