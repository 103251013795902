footer {
  @media screen and (max-width: 768px) {
    .logo {
      width: fit-content;
      display: flex;
    }
    .sumarte {
      display: block;
      width: 55%;
    }
  }

  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
  padding: 4.5rem 0;
  .logo,
  .sumarte,
  .descargar,
  .more-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 2rem;
  }
  .descargar,
  .sumarte {
    p {
      font-size: 0.875rem;
    }
  }
  .logo {
    img {
      object-fit: cover;
      max-width: 120px;
      max-height: 100%;
      width: auto;
      height: auto;
      @media screen and (max-width: 500px) {
        max-width: 100px;
      }
    }
  }
  .descargar {
    img {
      max-width: 105px;
      width: 100%;
      margin: 40px 0;
      max-height: 31px;
      & + img {
        margin-left: 1rem;
      }
    }
  }
  .more-info {
    a {
      font-size: 0.875rem;
      line-height: 1rem;
      & + a {
        margin-top: 0.5rem;
      }
    }
    .social-media {
      width: 100%;
      margin: 2rem 0;
      padding: 0;
      a {
        margin-top: 0;
      }
      img {
        width: 1.25rem;
      }
    }
  }
  h5 {
    color: #8c4091;
    font-size: 1.1rem;
    line-height: 1.4rem;
    margin-bottom: 0.7rem;
  }
  p,
  a {
    color: #666666;
    line-height: 1.5rem;
  }
  button {
    margin: 1.5rem 0;
  }

  @media screen and (min-width: 768px) {
    margin-bottom: 0;
  }
}
