.convo-card {
  height: 170px;
}
.convo-img {
  width: 100%;
  height: 170px;
}

//Mobile
@media (max-width: 767px) {
  .convo-img {
    max-height: 250px;
  }
  .convo-card {
    height: 400px;
  }
}

//large
@media (max-width: 1200px) {
  .convo-img {
    // display: block;
    // width: 100%;
    max-height: 170px;
  }
  // .convo-card {
  //   height: 400px;
  // }
}
