.titleOwn {
  color: #333333;
  font-weight: bolder !important;
}
// .backButton {
//   display: flex;
//   color: #626262;
//   font-weight: bold;
//   align-self: flex-end;
// }

.backButton {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  border: none;

  font-size: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    max-width: 0.8rem;
    width: 100%;
    object-fit: scale-down;
    margin-right: 0.5rem;
  }
}
.uploadDiv {
  border-radius: 25px;
  border: 2px solid #999999;
  padding: 20px;
  width: 100%;
  // height: 150px;
  border-style: dashed;
}
.terminos {
  text-align: justify;
}
.recreoBlue {
  color: #12b6ca;
}
.navItem {
  // border-bottom: 2px solid #8b2c75;
  // color: #8b2c75 !important;
  padding: 1em;
}
.navLink {
  cursor: pointer;
  // border-bottom: 2px solid #8b2c75;
  color: #3f3f3f !important;
  padding: 1em;
}
.navLinkActive {
  cursor: pointer;
  border-bottom: 2px solid #8b2c75;
  color: #8b2c75 !important;
  padding: 1em;
}
.navTabOwn {
  border-bottom: transparent !important;
}

.container-accordion {
  margin: auto;
  .item {
    button.accordion {
      cursor: pointer;
      text-align: left;
      outline: none;
      width: 100%;
      background-color: transparent;
      border: none;
      border-radius: 0;
      p {
        display: flex;
        padding: 25px;
        // padding-right: 2;
        color: #333;
        font-size: 15px;
        // background-color: transparent;
        width: 100%;
      }
    }
    button.accordion p span {
      width: 1.25rem;
      height: 1.25rem;
      float: right;
      margin-left: 2%;
      // content: url("../../assets/images/Arrow.svg");
      //content: url("../../../assets/images/Arrow.svg");
      // margin-left: auto;
      // margin-right: -10px;
    }

    .panel {
      max-height: 0;
      overflow: hidden;
      transition: 0.2s ease-out;
      p {
        font-size: 1rem;
        line-height: 1.9rem;
        text-align: justify;
        margin-bottom: 1.5%;
      }
    }
    // hr {
    //   margin-top: 0;
    //   margin-bottom: 3%;
    // }

    &.active {
      button.accordion p span {
        // padding-left: 2%;
        transition: 0.2s;
        transform: rotate(180deg);
      }
      .panel {
        padding: 0% 50px;
        padding-bottom: 1%;
        background-color: transparent;
        max-height: fit-content;
        display: block;

        //transition: 0.2s ease-out;
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .container-accordion .item .panel p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
  }
}
.fakeButton {
  display: block;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  padding: 0.5rem 1.3rem;
  border-radius: 1.8rem;
  border: none;
  border: 1px solid white;
  color: white;
  cursor: pointer;
  font-weight: normal;
  font-size: 1rem;
  line-height: 20px;
  position: relative;
  background-color: #626262;
  font-size: small;
  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
.fakeButton:hover {
  background-color: transparent;
  color: #626262;
  border-color: #626262;
}
.docIcon {
  cursor: pointer;
  &:hover {
  }
}
.cardOwn {
  // width: 40% !important;
}
.linkTo {
  text-decoration: none;
  // color: none;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: #333333;
  }
}
.search-bar {
  margin: 1em;
  height: 40px;
  border: 1px solid gray;
  border-radius: 2rem;
  width: 35%;
  &::placeholder {
    padding: 0.5em;
  }
  & {
    padding: 1em;
  }
}
@media only screen and (max-width: 999px) {
  /* For mobile phones: */
  .search-bar {
    width: 90%;
  }
}
