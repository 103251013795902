.desktop {
  display: none;
}
.sticky {
  top: -1px !important;
}
.mobile {
  display: initial;
  header {
    padding: 0.8rem 0;
    &.scrolled {
      padding: 0;
    }
    .logo {
      img {
        max-width: 5rem;
      }
    }
  }
  .snackBar {
    z-index: 4;
    position: relative;
    &.scrolled {
      height: 0;
    }
  }
}
.navigation {
  display: flex;
  justify-content: center;
  align-items: center;
  ul {
    padding: 0;
    display: flex;
    a {
      margin-right: 1.9rem;
      color: white;
    }
  }
  .userCta {
    display: flex;

    a + a {
      margin-left: 1rem;
    }
  }
}
.userMenu {
  position: absolute;
  top: 4rem;
  right: 0;
  background: #ffffff;
  transform-origin: top center;
  padding: 1.2rem 1.9rem;
  min-width: 300px;
  border-radius: 5px;
  transform: translateX(-15px);
  @media screen and (max-width: 992px) {
    transform: translateX(0);
  }
  a,
  span {
    color: #666666;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    cursor: pointer;
  }
}

.menuMobile {
  background: white;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  z-index: 2;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transform: translateY(-100%);
  opacity: 1;
  transition: all ease-in 1s;
  position: absolute;
  a {
    color: black;
    display: block;
    margin-bottom: 2rem;
  }
}

.active {
  transform: translateY(0);
  opacity: 1;
  transition: all ease-in 1s;
}

.separador {
  height: 1px;
  width: 100%;
  background: #cccccc;
  margin-bottom: 2rem;
}

.snackBar {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)),
    #12b6ca;
  .navigation {
    display: flex;
    align-items: center;
    a {
      font-size: 0.9rem;
    }
    ul {
      a {
        color: rgba(255, 255, 255, 0.7);
        margin-left: 1rem;
        font-size: 0.85rem;
      }
    }
    .appDownload {
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        color: white;
        margin: 0 0.7rem;
        font-size: 0.9rem;
      }
      a + a {
        margin-left: 0.5rem;
      }
      img {
        max-width: 1.3rem;
        width: 100%;
      }
    }
  }
  .languages {
    display: flex;
    justify-content: center;
    align-items: center;
    div {
      color: rgba(255, 255, 255, 0.7);
      text-transform: uppercase;
      font-weight: bold;
      font-size: 0.7rem;
      margin-right: 1rem;
      cursor: pointer;
      &.active {
        color: white;
      }
    }
  }
  &.scrolled {
    height: 0;
  }
}

@media screen and (min-width: 992px) {
  .desktop {
    display: initial;
    header {
      padding: 1.2rem 0;
      transition: all 0.2s ease-in;
      &.scrolled {
        transition: all 0.5s ease-in;
        padding: 0.8rem 0;
        -webkit-overflow-scrolling: touch;
        .logo {
          img {
            max-width: 5rem;
          }
        }
      }
    }
    .logo {
      img {
        max-width: 8.75rem;
      }
    }
  }
  .mobile {
    display: none;
  }
}

.hasNotifications {
  width: 9px;
  height: 9px;
  position: absolute;
  transform: translateX(8px) translateY(6px);
  border-radius: 50%;
  background-color: #eb0189;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 7px !important;
  color: white !important;
}

.miAgenda {
  width: 16px;
  height: 16px;
  filter: brightness(0) saturate(100%) invert(16%) sepia(96%) saturate(5081%)
    hue-rotate(315deg) brightness(90%) contrast(107%);
}
