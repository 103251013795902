.BTNBack {
  position: absolute;
  color: black;
  border: none;
  img {
    filter: brightness(0.5);
    margin-right: 5px;
  }
}

.BTNBackRotate {
  position: absolute;
  color: white;
  border: none;
  background: rgba(0, 0, 0, 0.3);
  img {
    margin-right: 5px;
  }
}
