.card {
  width: 100%;
  display: flex;
  height: max-content;
  justify-content: center;
  align-items: center;
  margin: auto;
}
.cardContent {
  p {
    font-family: Encode, sans-serif;
    font-weight: normal;
    font-size: 100%;
  }
}
.subIcons {
  margin-top: 3%;
  display: flex;
  justify-content: space-between;
}
