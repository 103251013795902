.tabs {
  border-radius: 5px;
  width: 100%;
  ul {
    justify-content: flex-start;
    padding-right: 1.5rem;
    @media screen and (min-width: 768px) {
      justify-content: center;
      padding-right: 0;
    }

    li {
      cursor: pointer;
      padding: 1.5rem 0;
      color: #666666;
      margin: 0;
      transition: all ease-in 0.5s;

      & + li {
        margin-left: 3rem;
      }
      h6 {
        width: max-content;
      }
    }
  }
  .searchFormWrapper {
    width: 100%;
  }
  form {
    label,
    input {
      margin: 0;
    }
    svg {
      bottom: 0 !important;
    }
  }
}

.content {
  margin: 2.5rem auto;

  @media screen and (min-width: 768px) {
    margin: 3rem auto;
  }
}

li.active {
  border-bottom: 1px solid #8b2c75;
  h6 {
    color: #8b2c75;
  }
}
.textBlod {
  font-weight: normal;
}
