.carrusel-hero {
  position: relative;
  .points-carrusel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    position: absolute;
    width: 100%;
    top: 2rem;
    padding: 0 1.5rem;
    .points-container {
      display: flex;
      justify-content: space-between;
      display: flex;
      transition: none;
      .point {
        z-index: 10;
        background: rgba(255, 255, 255, 0.25);
        height: 15px;
        width: 15px;
        border-radius: 50%;
        cursor: pointer;
        &:not(:last-of-type) {
          margin-right: 0.8rem;
        }
        &.selected {
          background: #ffffff;
        }
      }
    }
    .backButton {
      background: rgba(0, 0, 0, 0.3);
      border-radius: 2rem;
      padding: 0.5rem 1rem;
      border: none;

      font-size: 0.8rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        max-width: 0.8rem;
        width: 100%;
        object-fit: scale-down;
        margin-right: 0.5rem;
      }
    }
  }
  .carrusel-item {
    display: none;
    img {
      width: 100%;
      min-height: 480px;
      height: 480px;
      object-fit: cover;
      object-position: center;
    }
    &.active {
      display: block;
      img {
        animation-name: selected;
        animation-duration: 0.4s;
        @keyframes selected {
          from {
            opacity: 0;
          }
          to {
            opacity: 1;
          }
        }
      }
    }
  }
  &.home {
    .points-carrusel {
      left: 0;
      right: 0;
      bottom: 2rem;
      top: auto;
      align-items: flex-end;
      .data.content {
        cursor: pointer;
        .subtitle {
          font-size: 1.1rem;
        }
        .title {
          margin: 1.3rem auto;
          font-size: 2.2rem;
        }
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .carrusel-hero {
    .points-carrusel {
      .carrusel-item {
        img {
          width: 100%;
          height: 480px;
        }
      }
    }
  }
}

@media screen and (max-width: 550px) {
  .carrusel-hero {
    .carrusel-item {
      img {
        min-height: 280px;
        max-height: 280px;
        height: 100%;
      }
      iframe {
        height: 280px !important;
      }
    }
    &.home {
      .points-carrusel {
        .data.content {
          .title {
            font-size: 1.5rem;
            margin: 0.5rem auto;
          }
          .subtitle {
            font-size: 1rem;
          }
        }
      }
    }
  }
}
