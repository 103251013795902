.termsAndConditions {
  width: 70%;
  margin-bottom: 100px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  p {
    color: #333333;
    font-family: Encode, "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
  }
  a,
  button {
    font-family: Encode, "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
  }
  a {
    color: #333333;
  }
}

.titleFlexMobile {
  > div {
    @media screen and (max-width: 420px) {
      flex-direction: column;
    }
  }
}
