div.safe-destinations {
  margin-bottom: 10%;
  ul {
    margin-top: 90px;
    margin-bottom: 90px;
    .item {
      margin-top: 30px;
      margin-bottom: 30px;
      img {
        margin: 1.5% 3%;
      }
      p {
        display: flex;
        margin-top: auto;
        margin-bottom: auto;
        font-weight: 500;
        font-size: 20px;
        line-height: 25px;
        font-style: normal;
      }
      svg {
        min-width: 50px;
        width: 50px;
        height: 50px;
        margin-right: 3%;
      }
    }
    .vaccination-wrapper {
      border-radius: 8px;
      font-size: 20px;
      margin-top: 84px;
      padding: 32px 64px;
      width: 100%;
    }
  }
}

@media screen and (max-width: 550px) {
  div.safe-destinations {
    ul {
      margin-top: 40px;
      margin-bottom: 120px;
      .item {
        img {
        }
        p {
          font-size: 16px;
          line-height: 20px;
        }
        svg {
          margin-right: 3%;
        }
      }
      .vaccination-wrapper {
        font-size: 16px;
        margin-top: 64px;
        padding: 16px 32px;
      }
    }
  }
}
