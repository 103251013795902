div.FAQ {
  margin-bottom: 6%;
  .title {
    text-align: center;
  }
}

@media screen and (max-width: 550px) {
  div.FAQ {
    margin-bottom: 35%;
  }
}
