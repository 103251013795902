.settings {
  .mainContainer {
    justify-content: space-between;
    align-items: flex-start;
    @media screen and (max-width: 768px) {
      justify-content: center;
      align-items: center;
    }
  }
  .settingsBox {
    border-radius: 5px;
    max-width: 410px;
    margin: 0 -15px;

    select {
      max-height: 40px;
      max-width: 310px;
      padding: 5px 20px;
    }
    .removeCityHall {
      cursor: pointer;
    }
    @media screen and (max-width: 768px) {
      &:not(:last-of-type) {
        margin-bottom: 2rem;
      }
    }
  }
  .togglerTitle {
    padding: 2rem 0 1rem;
    border-bottom: 1px solid #dddddd;
    margin-bottom: 2rem;
    input[type="checkbox"] {
      width: auto;
      cursor: pointer;
      position: relative;
      min-width: 32px;
      &:before {
        content: "";
        width: 32px;
        height: 16px;
        background-image: url("../../assets/images/toggleInactive.svg");
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        border-radius: 25%;
        transition: all ease-in-out 0.5s;
      }
      &:after {
        content: "";
        background-color: white;
        width: 10px;
        height: 10px;
        display: block;
        position: absolute;
        right: 55%;
        top: 0;
        bottom: 0;
        margin: auto;
        border-radius: 50%;
        transition: all ease-in-out 0.5s;
      }
      &:checked {
        &:before {
          background-image: url("../../assets/images/toggleActive.svg");
          right: 0;
        }
        &:after {
          right: 10%;
        }
      }
    }
  }

  .formTitle {
    color: black;
  }

  .inactive {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.4;
  }
}
