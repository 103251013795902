.imageSide {
  background-repeat: no-repeat;
  background-position: top center;
  background-size: cover;
  margin-right: 2rem;
  img {
    visibility: hidden;
    max-height: 480px;
    object-fit: scale-down;
  }
}
.textSide {
  padding: 2.2rem;
  .top {
    h2 {
      font-size: 2.3rem;
      font-weight: bold;
      color: #8b2c75;
    }
  }
}
.description {
  font-size: 18px;
  line-height: 30px;
  color: #333333;
  &:not(:last-of-type) {
    margin-bottom: 1rem;
  }
  @media screen and (min-width: 768px) {
    margin-bottom: 1rem;
  }
}
.descriptionBlock {
  max-height: 250px;
  overflow-y: scroll;
  scrollbar-color: #eb0189;
  scrollbar-width: thin;
  &::-webkit-scrollbar-thumb {
    background: #eb0189;
    height: 5px;
    border-radius: 4px;
  }
}
.separador {
  height: 1px;
  width: 100%;
  background: #cccccc;
  margin: 2rem auto;
  @media screen and (min-width: 768px) {
    margin: 3rem auto;
  }
}
.featuresSeparator {
  order: 2;
}
.spotify {
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 2em auto;
  float: right;
  &.desktop {
    display: none;
  }
  &.mobile {
    display: flex;
    order: 3;
    &.description {
      order: -1;
      align-items: flex-start;
    }
  }
  @media screen and (min-width: 980px) {
    justify-content: flex-end;
    margin: 0;
    &.desktop {
      display: block;
    }
    &.mobile {
      display: none;
    }
  }
}

.map {
  margin-bottom: 5.5rem;
}
.agendaContainer {
  border-radius: 5px;
}
.agendaRecesses {
  padding: 2rem 0;
}
.controlAgenda {
  img {
    max-width: 19px;
  }
  @media screen and (min-width: 768px) {
    img {
      max-width: 30px;
    }
  }
}

.buttonDay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: #666666;
    &.numericDate {
      color: black;
      font-size: 1rem;
      padding: 10px;
      border-radius: 50%;
      cursor: pointer;
      &.past {
        opacity: 0.3;
      }
      @media screen and (min-width: 768px) {
        padding: 12px 14px;
      }
    }
  }
}

.active {
  p {
    &:not(:first-of-type) {
      background: #8c4091;
      color: white;
    }
  }
}

.hasEvent {
  background: rgba(139, 44, 117, 0.15);
}

.agendar {
  img {
    margin: -15px;
    margin-left: -0.5rem;
    max-width: 25px !important;
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7460%)
      hue-rotate(125deg) brightness(106%) contrast(100%);
  }
}
