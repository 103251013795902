.datePicker {
  background-color: white;
  display: flex;
  flex-direction: column;
  max-height: min-content;
  height: max-content;
  position: fixed;
  top: 20%;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 999999999;
}

.onCloseButton {
  all: unset;
  position: absolute;
  top: 10px;
  left: 10px;
}
.buttonDay {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: #666666;
    &.numericDate {
      color: black;
      font-size: 1rem;
      padding: 10px;
      border-radius: 50%;
      cursor: pointer;
      &.past {
        opacity: 0.3;
      }
      @media screen and (min-width: 768px) {
        padding: 12px 14px;
      }
    }
  }
}

.active {
  p {
    &:not(:first-of-type) {
      background: #8c4091;
      color: white;
    }
  }
}
