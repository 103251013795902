.cardSchedule {
  max-height: 170px;
}
.right {
  div {
    min-width: max-content;
    @media screen and (max-width: 400px) {
      display: none;
    }
  }
  button {
    padding: 3px 8px;
    margin-top: auto;
    margin-left: auto;
    @media screen and (max-width: 400px) {
      padding: 1px 4px !important;
      display: flex;
      margin-top: 99px;
    }
  }
  @media screen and (max-width: 400px) {
    right: 0;
    height: 170px !important;
    position: absolute;
    display: block !important;
  }
}
