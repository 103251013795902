.register {
  padding: 4rem 0;
  color: #666666;
  h5 {
    color: #8c4091;
  }
  .terms,
  .subtitle {
    font-size: 0.8rem;
    line-height: 1.1rem;
  }
  .subtitle {
    margin: 0.5rem auto;
  }

  .separador {
    height: 1px;
    width: 100%;
    background: #cccccc;
    margin: 5rem 0 2rem;
  }

  .buttonGroup {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2.5rem 0;
    button,
    a {
      max-width: 410px;
      width: 100%;
      color: #333333;
      &.blue {
        border: 1px solid #3b5998;
      }
      &.red {
        border: 1px solid #bb001b;
      }
      &.purple {
        border: 1px solid #8b2c75;
      }
      &:nth-of-type(2) {
        margin: 0.7rem 0;
      }
      img {
        margin-right: 0.7rem;
      }
    }
  }
  .loginCta {
    margin: 0 0 1.5rem;
  }
}
