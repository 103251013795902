.password-recovery-request {
  .title {
    margin-bottom: 0.8rem;
  }
  h6 {
    font-size: 14px;
    line-height: 17px;
    text-align: center;
  }
  form {
    margin: 3.8rem auto;
    max-width: 350px;
    button {
      margin: 0.9rem auto;
    }
  }

  .separador {
    min-height: 1px;
    max-width: 410px;
    width: 100%;
    background: #cccccc;
    margin: 0 auto 2rem;
  }
  @media screen and (min-width: 768px) {
    margin: 2rem auto;
  }
}
