.bottonCard {
  @media screen and (max-width: 400px) {
    margin-bottom: 20px;
  }
  button {
    width: max-content;
    @media screen and (max-width: 400px) {
      position: absolute;
      margin-bottom: -14px;
      margin-left: -3px;
    }
  }
  > p {
    @media screen and (max-width: 400px) {
      visibility: hidden;
    }
  }
}
