.landing-header {
  background-color: #12b6ca;
  img {
    visibility: visible;
    object-fit: none;
    height: unset;
    width: unset;
  }
}

.landing-container {
  position: fixed;
  z-index: 99999999;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: white;
  max-height: 100vh;
}

.landing,
.landingText,
.landing-header,
.footer-landing {
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: flipH;
  -ms-filter: "FlipH";

  h3 {
    color: #00b2c8;
    @media screen and (max-width: 764px) {
      font-size: 18px;
    }
  }

  img {
    visibility: visible;
    width: 100%;
  }

  .downloadLinks {
    margin-top: 0;
    margin-bottom: 0;
    width: 300px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    transform: scaleX(-1);
    a {
      height: 60px !important;
    }
  }

  .contactForm {
    background-color: #fff;
    background-image: none;
    display: block;
    .banner-landing {
      position: initial;
      height: 60vh;
      transform: scaleX(-1);
      width: 100%;
      background-position: top;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .footer-landing {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 64px;
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: top;
    padding: 12px 8px;
    padding-top: 32px;

    .landing-footer-logo {
      margin-top: 20px;
      max-width: 35%;
      @media screen and (max-width: 768px) {
        max-width: 50%;
      }
    }

    .social-links {
      display: flex;
      justify-content: space-between;

      a {
        width: 20px;
        height: 20px;
        margin: 12px;

        img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}
.img-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .claim {
      height: 30%;
      width: 40%;
    }
  }
  .landingText {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 32px;
    .banner-text {
      height: 100px !important;
      margin-top: 50px;
      transform: scaleX(-1);
    }
  }
}

@media screen and (max-width: 1400px) {
  .landing {
    .contactForm {
      .banner-landing {
        margin-bottom: 100px;
        background-size: cover;
      }
    }
  }
  .claim {
    width: 500px !important;
    height: 200px !important;
  }
  .footer-landing {
    padding-top: 10px !important;
  }
  .banner-text {
    margin-top: 10px !important;
  }
}
@media screen and (max-width: 500px) {
  .banner-landing {
    background-size: cover !important;
    background-image: url("../../assets/images/banner-mobile.png") !important;
  }
  .footer-landing {
    padding-top: 0px !important;
  }
  .claim {
    width: 400px !important;
    height: 150px !important;
  }
  .downloadLinks {
    width: 150px;
  }
}

@media screen and (max-width: 350px) {
  .claim {
    width: 300px !important ;
    height: 100px !important;
  }
  .footer-landing {
    padding-top: 0px !important;
  }
  .downloadLinks {
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
    .appstore,
    .playstore {
      height: 50px !important;
      width: 150px;
    }
  }
}

@media screen and (max-height: 700px) {
  .banner-text {
    margin-top: 10px !important;
  }
}

@media screen and (max-height: 600px) {
  .claim {
    width: 300px !important ;
    height: 100px !important;
  }
}
