.searchResults {
  background-color: #f6f6f6;
  position: sticky;
  z-index: 5;
  top: calc(0% + 157px);
  margin-bottom: 3.5rem;

  .section-title {
    img {
      max-width: 19px;
      width: 100%;
      margin-right: 0.9rem;
    }

    h5 {
      font-size: 1.3rem;
    }
  }

  .noResults {
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.block {
  .individual {
    max-width: auto;
    padding-left: 0;
  }
}

.amenities {
  & > div {
    padding-left: 0;

    &:not(:first-of-type) {
      padding-right: 0;
    }
  }
}

@media screen and (min-width: 768px) {
  .block {
    & > div {
      max-width: 24%;
      padding: 0;
    }
  }

  .amenities {
    & > div {
      &:first-of-type {
        padding-left: 0;
      }
    }
  }
}

.banner-agenda-cultural {
  width: 100%;
  margin: 40px auto 25px;
  overflow: hidden;
  padding: 0;
  display: block;

  img {
    width: 100%;
    height: auto;
  }
}
